import { createRouter, createWebHistory } from 'vue-router';
import Main from '@/components/MainPage.vue';
import Project from '@/components/ProjectsPage.vue';
import ProjectDetail from '@/components/ProjectDetail.vue'
import AboutUs from '@/components/AboutUs.vue'
import Latest from '@/components/LatestProjects.vue'

const routes = [
    {
        path:'/',
        name:'Home',
        component: Main,
    },
    {
        path:'/project',
        name:'Project',
        component: Project,
    },
    {
        path:'/project/:name',
        name:'ProjectDetail',
        component: ProjectDetail,
        props: true,
    },
    {
        path:'/aboutus',
        name:'AboutUs',
        component:AboutUs,
    },
    {
        path:'/latest',
        name:'LatestProjects',
        component: Latest,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;