<template>
  <div class="mainpage">
    <div class="mainpage_background"></div>
    <div class="main_brand">
        <div class="main_brand_content">
            <p class="content_1">in</p>
            <p class="content_2">BETWEEN</p>
        </div>
    </div>
    <div class="main_manifest">
        <div class="main_manifest_content">
            <p class="content_3">BEtween is an artful destiny in<br>space, time and tendencies,<br>just like architecture.</p>
            <p class="content_4">之间是时间，空间与各种倾向性的微妙注定,<br>正如建筑一样。</p>
        </div>
    </div>
    <div class="main_projects">
        <div class="main_projects_title">
            <div class="title_content">
                <p class="title_content_text">FEATURED PROJECTS</p>
            </div>
        </div>
        <div class="main_projects_column">
            <div class="project_column" id="column_1">
                <div class="project_content">
                    <p class="project_EN">Chengxi Urban Design</p>
                    <p class="project_CN">两岸城曦</p>
                
                    <button class="project_button" @click="goToProjectDetail('Chengxi')">Read More</button>
                </div>
            </div>
            <div class="project_column" id="column_2">
                <div class="project_content">
                    <p class="project_EN">Winland Complex</p>
                    <p class="project_CN">英蓝城市综合体</p>
                    <button class="project_button" @click="goToProjectDetail('Winland Complex')">Read More</button>
                </div>
            </div>
            <div class="project_column" id="column_3">
                <div class="project_content">
                    <p class="project_EN">Shenzhen Bibliopolis</p>
                    <p class="project_CN">深圳书城湾区城</p>
                    <button class="project_button" @click="goToProjectDetail('Bibliopolis Shenzhen')">Read More</button>
                </div>
            </div>
            <div class="project_column" id="column_4">
                <div class="project_content">
                    <p class="project_EN">Xipai Village Homestay</p>
                    <p class="project_CN">西排村民宿</p>
                    <button class="project_button" @click="goToProjectDetail('Xipai Village Homestay')">Read More</button>
                </div>
            </div>
        </div>
        <div class=""></div>
    </div>
    <div class="main_contact">
        <div class="main_contact_content">
            <p id="contact_info">
                0031 (0) 15 364 83 86 <br>info@between.nl<br>Hugo de Grootstraat 394, 2613 VD Delft, Netherlands
            </p>
        </div>
    </div>
    <div class="main_blank"></div>
  </div>
</template>

<script>
export default {
  name: 'MainPage',
  data(){
    return{
        projects:[]
    };
  },
  
  mounted(){
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    handleScroll(){
        var scrolled = window.pageYOffset;
        var background = document.querySelector('.mainpage_background');
        if (background){
            var rate = -scrolled*0.2;
            background.style.transform = 'translateY(' + rate + 'px)';
        } 
    },
    goToProjectDetail(projectName){
        this.$router.push({name:'ProjectDetail',params: { name:projectName} });
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
}

.mainpage{
    display: flex;
    flex-direction: column;
    margin: 0;
    z-index: -2;
}

.mainpage_background{
    width: 100%;
    height: 200vh;
    position: fixed;
    background: url('@/assets/background_1.webp');
    background-size: cover;
    background-position: center;
    z-index: -1;
}

.main_brand{
    height: 150vh;
    background-color: rgba(203, 61, 61, 0);
    overflow: hidden;
}

.main_brand_content{
    background-color: rgba(205, 35, 35, 0);
    margin-top: 42vh;
    position: relative;
}

.content_1{
    font-family: 'Raleway', sans-serif;
    font-weight: 900;
    font-size: 160px;
    background-color: rgba(255, 255, 255, 0);
}

.content_2{
    position:absolute;
    top: -5vh;
    left: 48vw;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 50px;
    background-color: rgba(255, 255, 255, 0);
}

.main_manifest{
    height: 150vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main_manifest_content{
    margin-top: 5vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.6)
    
}

.content_3{
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 60px;
}

.content_4{
    font-family: 'Raleway', sans-serif;
    font-weight: 750;
    font-size: 40px;
}

.main_projects{
    height: 137vh;
    background-color:rgba(255, 255, 255, 0.6);
    
}

.main_projects_title{
    height: 35vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title_content{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 50px;
    letter-spacing: 5px;
}

.main_projects_column{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    height: 100vh;
    margin-bottom: 0;
}

.project_column{
    margin-right: 1vw;
    margin-left: 1vw;
    
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit:cover;
    overflow: hidden;
    margin-bottom: 0;
}

#column_1{
    background: url('@/assets/index_project_1.webp');   
    background-size: cover;
    background-position: center;
}
#column_2{
    background: url('@/assets/index_project_2.webp');
    background-size: cover;
    background-position: center;
}
#column_3{
    background: url('@/assets/index_project_3.webp');
    background-size: cover;
    background-position: center;
}
#column_4{
    background: url('@/assets/index_project_4.webp');
    background-size: cover;
    background-position: center;
}

.project_content{
    height: 45vh;
    width: 100%;
    margin-top: 30vh;
    margin-bottom: 30vh;
    background-color: rgba(255, 255, 255, 0.8)
}

.project_EN{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 50px;
    margin-bottom: 0;
}

.project_CN{
    font-family:'Anton', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 2vh;
}

.project_button{
    width: 7vw;
    height: 4vh;
    background-color: black;
    color: white;
    border: solid 1px black;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    font-size: 15px;

    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.project_button:hover{
    color: black;
    background-color: white;
}

.main_contact{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 30vh;
    background-color: rgba(255, 255, 255, 0.6);
    margin-top: 1vh;
}

#contact_info{
    color: white;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 5vh;
}

.main_blank{
    height: 15vh;
    background: rgba(255, 255, 255, 0);
}

</style>