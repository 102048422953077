<template>
  <div class="projectspage">
    <div class="projectdetail_background"></div>
    <div class="project_category">
      
    </div>
    <div class="project_background">
      <div class="project_detail" v-if="project">
        <div class="detail_info">
          <h1 class="info_title">{{project.name}}</h1>
          <h2 class="info_title_CN">{{project.nameCN}}</h2>
          <p1 class="info_text"><br>Location: {{project.location}}<br>Size: {{project.size}}<br>Type: {{project.type}}<br>Status: {{project.status}}<br></p1>
          <p2 class="info_text_CN"><br>地点：{{project.locationCN}}<br>规模：{{project.size}}<br>类型：{{project.typeCN}}<br>时间：{{project.time}}<br>状态：{{project.statusCN}}</p2>
        </div>
        <div class="detail_poster">
          <img :src="getImageUrl(project.images[0])" class="detail_poster_image">
        </div>
        <div class="detail_description">
          <p3 class="description_EN">{{project.description}}<br></p3>
          <p4 class="description_CN"><br>{{project.descriptionCN}}</p4>
        </div>
        <div class="detail_gallery">
          <div v-for="(image, index) in project.images" :key="index" class="gallery_item" @click="showImage(index)">
            <img :src="getImageUrl(image)" class="gallery_image" alt="Project Image">
          </div>
        </div>
        
      </div>
      <div class="detail_footer">
          <button class="detail_button">Back</button>
          <button class="detail_button">Next</button>
      </div>
    </div>

    <div class="aboutus_blank_2"> 
    </div>  

    <vue-easy-lightbox
      :visible="visible"
      :imgs="imageUrls"
      :index="index"
      @hide="handleHide"
    />
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
  name: 'ProjectDetail',
  components:{
    VueEasyLightbox
  },
  props: ['name'],
  data(){
    return{
      project:null,
      visible: false,
      index: 0,
      imageUrls: []
    };
  },
  created(){
    if(this.name){
      this.loadProjects();
    }
  },
  methods:{
    async loadProjects(){
      try {
        const response = await fetch('/projects.json');
        if(!response.ok){
          throw new Error('Network response was not OK');
        }
        const data = await response.json();
        this.project = data.find(p=>p.name === this.name);

        if(!this.project){
          console.error(`Project not found: ${this.name}`);
        } else {
          this.imageUrls = this.project.images.map(image => this.getImageUrl(image));
          console.log('imageUrls:', this.imageUrls);
        }
      }catch(error){
        console.error("Error loading projects", error);
      }
    },
    getImageUrl(path){
      return `${path}`;
    },
    showImage(index){
      this.index = index;
      this.visible = true;
    },
    handleHide(){
      this.visible = false;
    }
   
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
}

*{
  margin: 0;
  padding: 0;
}

.projectdetail_background{
    width: 100%;
    height: 200vh;
    position: fixed;
    background: url('@/assets/background_2.webp');
    background-size: cover;
    background-position: center;
    opacity: 0.3;
    z-index: -1;
}

.project_category{
  margin-top: 12vh;
  margin-left: 30vw;
  margin-right: 30vw;
  height: 15vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.category_button{
  margin-top: 5vh;
  margin-bottom: 5vh;
  margin-right: 0.5vw;
  margin-left: 0.5vw;
  background-color: rgb(240,240,240);
  border: none;
  color:rgb(125, 125, 125);

  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-weight: 100;
  font-style:inherit;
  font-size: 18px;
}

.project_background{
    background-color: rgba(0, 0, 0, 0.7);
}

.project_detail{
  padding-top: 5vh;
  margin-left: 20vw;
  margin-right: 20vw;
  display: flex;
  flex-wrap: wrap;

}

.detail_info{
  width:30%;
  height: 50vh;

  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: left;
}

.info_title{
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 25px;
}

.info_title_CN{
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 22px;
}

.info_text{
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
}

.info_text_CN{
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 14px;
   line-height: 1.3;
}

.detail_poster{
  width: 70%;
  height: 50vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detail_posteer_image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.detail_description{
  color: white;
  margin-top: 5vh;
  min-width: 100%;
  margin-bottom: 5vh;
  text-align: left;
}

.description_EN{
  color: white;
  margin-bottom: 10vh;
  font-family: 'Raleway', sans-serif;
  font-weight: 450;
  font-size: 14px;
}

.description_CN{
  margin-bottom: 10vh;
  font-family: 'Raleway', sans-serif;
  font-weight: 250;
  font-size: 14px;
}

.detail_gallery{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.gallery_item{
  height: 20vw;
  margin: 0.5vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.gallery_item::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(99,99,99,0);
  transition: background-color 0.3s ease;
  z-index: 2;
}

.gallery_image {
  object-fit: contain;
  object-position: center; 
}

.gallery_item:hover::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(99,99,99,0.6);
  z-index: 2;
}

.detail_footer{
  margin-left: 20vw;
  margin-right: 20vw;
  display: flex;
  justify-content: flex-end;
}

.detail_button{
  margin-top: 3vh;
  margin-bottom: 3vh;
  margin-left: 0.2vw;
  margin-right: 0.2vw;
  width: 8vw;
  height: 6vh;
  background-color: rgb(99, 99, 99);
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  font-size: 16px;
}


.aboutus_blank_2{
  height: 14vh;
}

</style>