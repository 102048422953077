<template>
    <body>
        <div class="header">
            <div class="header_nav">
                <ul class="nav_list">
                    <li class="nav_item"><router-link to="/">HOME</router-link ></li>
                    <li class="nav_item"><router-link to="/project">PROJECT</router-link></li>
                    <li class="nav_logo">
                        <img src='@/assets/icon_1.png' class="nav_logo_img">
                    </li>
                    <li class="nav_item"><router-link to="/aboutus">ABOUT US</router-link></li>
                    <li class="nav_item"><router-link to="/latest">LATEST</router-link></li>
                </ul>
            </div>
        </div>
        <div class="footer">
        </div>
    </body>
</template>

<script>
export default {
  name: 'NaviPage',
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');



html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

*{
    margin: 0;
    padding: 0;
}

.header{
    position: fixed;
    top: 0;
    margin: 0;
    padding: 0;
    height: 12vh;
    width: 100vw;
    background-color: rgb(240, 240, 240);
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
}

.header_nav{
    width: 100vw;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
}

.nav_list{
    width: 50vw;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
}

.nav_item{
    margin-top: 6vh;
    margin-bottom: 1vh;
    margin-left: 0;
    margin-right: 0;
    list-style-type: none;
    text-align: center;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: background-color 0.3s ease;
}

.nav_item a{
    width: 100%;
    color: black;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;
}

.nav_item:hover{
    background-color: black;
}

.nav_item a:hover{
    color: white;
}

.nav_logo{
    margin-top: 3vh;
    margin-bottom: 1vh;
    margin-left: 30px;
    margin-right: 30px;
    list-style-type: none;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav_logo_img{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
}

.footer{
    position: fixed;
    height: 15vh;
    width: 100vw;
    bottom: 0;
    background-color: rgb(240, 240, 240,0.8);
    z-index: 3;
}

</style>