<template>
  <div class="latest_project">
    <div class="latest_background"></div>

    <div class="project_category">
    </div>

    <div class="latest_title">
      <h1 class="title_content">Latest Project 最新项目</h1>
    </div>

    <div class="latest_box" v-for="project in filteredProjects" :key="project.name">
      <div class="latest_content" >
        <div class="latest_content_info">
          <h1 class="info_title">
            {{project.name}}
          </h1>
          <h1 class="info_title_CN">
            {{project.nameCN}}
          </h1>
          <p class="info_text">
            <br>
            Location: {{project.location}}<br>
            Size: {{project.size}}<br>
            Type: {{project.type}}<br>
            Time: {{project.time}}<br>
            Status: {{project.status}}<br>
          </p>
          <p class="info_text_CN">
            <br>
            地点：{{project.locationCN}}<br>
            规模：{{project.size}}<br>
            时间：{{project.time}}<br>
            类型：{{project.typeCN}}<br>
            状态：{{project.statusCN}}<br>
          </p>
          <router-link :to="{ name:'ProjectDetail', params:{name:project.name}}" class="project_info">
            <button class="info_button">Read More</button>
          </router-link>
        </div>
        <div class="latest_content_poster">
          <img :src="project.images[0]" class="latest_content_image">
        </div>
      </div>
    </div>

    <div class="aboutus_blank_2">
      
    </div>    
  </div>
</template>

<script>
export default {
  name: 'LatestProjects',
  data(){
    return{
      projects:[],
      filteredProjects:[]
    };
  },
  created(){
    this.loadProjects();
  },
  methods:{
    async loadProjects(){
      try {
        const response = await fetch('/projects.json');
        if(!response.ok){
          throw new Error('Network response was not OK');
        }
        this.projects = await response.json();
        this.filterProjects(['Bibliopolis Shenzhen','CR Elderly Housing Beijing', 'Eastward Extended Section of Shenzhen Bay Avenue'])
      }catch(error){
        console.error("Error loading projects", error);
      }
    },
    filterProjects(names){
      this.filteredProjects = this.projects.filter(project => names.includes(project.name));
    },
    getImageUrl(path){
      return `${path}`;
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
}

*{
  margin: 0;
  padding: 0;
}

.latest_background{
    width: 100%;
    height: 200vh;
    position: fixed;
    background: url('@/assets/background_1.webp');
    background-size: cover;
    background-position: center;
    opacity: 0.5;
    z-index: -1;
}

.project_category{
  margin-top: 12vh;
  margin-left: 30vw;
  margin-right: 30vw;
  height: 15vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

}

.category_button{
  margin-top: 5vh;
  margin-bottom: 5vh;
  margin-right: 0.5vw;
  margin-left: 0.5vw;
  background-color: rgb(240,240,240);
  border: none;
}

.latest_title{
  background-color: rgb(255, 255, 255);
  height: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.latest_box{
   background-color: rgb(255, 255, 255);
}

.latest_content{
  height: 60vh;
  margin-left: 25vw;
  margin-right: 25vw;
  display: grid;
  grid-template-columns: 35% 65%;
  margin-bottom: 2vh;
}

.latest_content_info{
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.info_title{
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 25px;
}

.info_title_CN{
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 22px;
}

.info_text{
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
}

.info_text_CN{
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 14px;
   line-height: 1.3;
}

.info_button{
  margin-top: 3vh;
  width: 7vw;
  height: 5vh;
  background-color: black;
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  font-size: 15px;

  transition: background-color 0.3s ease, color 0.3s ease;
}

.info_button:hover{
  background-color: white;
  color: black;
}

.latest_content_poster{
  margin-left: 5vw;
  margin-top: 2vh;
  display: flex;
  width: calc(100%-5vw);
}

.latest_content_image{
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.aboutus_blank_2{
  height: 16vh;
}

</style>