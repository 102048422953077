<template>
  <div class="aboutus">
    <div class="aboutus_blank"> 
    </div>

    <div class="aboutus_title">ABOUT US</div>
    <div class="aboutus_content">
      <div class="aboutus_description">
        <p1 class="description_EN">We are a team of enthusiastic creators, who trust in the power of design for a better built environment. Composed of interior, architecture, landscape and urban designers, we operate complex projects on the international stage. We are capable to neatly handle various types and scales with ambition and quality.</p1>
        <p2 class="description_CN"><br><br>我们是一个相信设计力量的、充满热情的团队。由室内设计师、建筑师、景观设计师及城市设计师所组成，我们的团队在国际设计领域内完成复杂的设计项目。我们有能力在不同类型和尺度的设计项目上，保持同样的设计野心与质量。</p2>
        <p1 class="description_EN"><br><br>We explore design possibilities through extensive research, as a collective of diverse design experts with various design backgrounds, our approach to projects is carrying out series of multi-disciplinary, critical and analytical design experiments, iterative process by conceptual/computational modeling is one of the most efficient methods we mater at. </p1>
        <p2 class="description_CN"><br><br>我们在设计中探究宽广的可能性。作为一群各具特长的设计专家，我们通过多专业融合、批判性以及研究性的手段开展项目设计。多轮次的概念/数据模型推演是我们所擅长的工作方式之一。</p2>
        <p1 class="description_EN"><br><br>We believe, the value of good design is to achieve optimal solutions with limited resources, multi-dimensional context and whole life cycle. In between of these constraints, we strive with our intelligence for creative yet practical design solutions.</p1>
        <p2 class="description_CN"><br><br>我们相信，优秀的设计可以优化项目有限的资源投入、多维度的场所条件以及项目全生命周期的表现。在这些场景之间，我们致力于实现富有创意及实践性的设计解决方案。</p2>
      </div>
      <div class="aboutus_portrait">
        <div class="portrait_main">
          <img src="/assets/About_Us/WechatIMG3-1.jpeg" class="portrait_main_image">
        </div>
        
        <div class="portrait_sub">
          <div class="portrait_sub_cell">
            <img src="/assets/About_Us/2151613123557__pic.jpg" class="portrait_sub_image">
          </div>
          <div class="portrait_sub_cell">
            <img src="/assets/About_Us/1231525304607__pic-1.jpg" class="portrait_sub_image">
          </div>
          <div class="portrait_sub_cell">
            <img src="/assets/About_Us/1341525395965__pic_hd copy-1.jpg" class="portrait_sub_image">
          </div>
          <div class="portrait_sub_cell">
            <img src="/assets/About_Us/2141613123543__pic-1.jpg" class="portrait_sub_image">
          </div>
          <div class="portrait_sub_cell">
            <img src="/assets/About_Us/1201525304572__pic-1.jpg" class="portrait_sub_image">
          </div>
          <div class="portrait_sub_cell">
            <img src="/assets/About_Us/1311525395962__pic_hd-1.jpg" class="portrait_sub_image">
          </div>
          <div class="portrait_sub_cell">
            <img src="/assets/About_Us/1281525395954__pic_hd.jpg" class="portrait_sub_image">
          </div>
          <div class="portrait_sub_cell">
            <img src="/assets/About_Us/1351525395966__pic_hd.jpg" class="portrait_sub_image">
          </div>
          <div class="portrait_sub_cell">
            <img src="/assets/About_Us/1361525396287__pic copy.avif" class="portrait_sub_image">
          </div>
          <div class="portrait_sub_cell">
            <img src="/assets/About_Us/1211525304582__pic.avif" class="portrait_sub_image">
          </div>
        
        </div>
      </div>
    </div>

    <div class="contact_title">CONTACT</div>
    <div class="contact_content">
      <div class="contact_info"><i class="fa fa-map-marker" aria-hidden="true"></i>   Hugo de Grootstraat 394. 2613VD Delft</div>
      <div class="contact_info"><i class="fa fa-envelope-open" aria-hidden="true"></i> info@between.city</div>
      <div class="contact_info"><i class="fa fa-phone" aria-hidden="true"></i> 0031 (0) 15 364 83 86</div>
    </div>

    <form class="contact_form">
      <div class="form_column_1">
        <input class="form_input_1" placeholder="Name*" type="text">
        <input class="form_input_1" placeholder="Email*" type="text">
        <input class="form_input_1" placeholder="Subject" type="text">
      </div>
      <div class="form_column_2">
        <input class="form_input_2" placeholder="Message" type="text">
      </div>

    </form>

    <div class="contact_button">
      <button type="submit" class="contact_submit">SUBMIT</button>
    </div>

    <div class="aboutus_blank_2"> 
    </div>

  </div>
</template>

<script>
export default {
  name: 'AboutUs',
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
}

*{
  margin: 0;
  padding: 0;
}

.aboutus{
  margin-left: 22vw;
  margin-right: 22vw;
}

.aboutus_blank{
  height: 12vh;
}

.aboutus_title{
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 40px;
  text-align: left;
  margin-bottom: 10vh;
}

.aboutus_content{
  display: grid;
  grid-template-columns: 50% 50%;
}

.aboutus_description{
  margin-right: 4vw;
  text-align: left;
}

.description_EN{
  margin-bottom: 10vh;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.description_CN{
  margin-bottom: 10vh;
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  font-size: 14px;
}

.aboutus_portrait{
  margin-left: 4vw;
  margin-right: 0vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.portrait_main{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.4vw;
}

.portrait_main_image{
  width: 100%;
  height: auto;
  object-fit: cover;
}

.portrait_sub{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.portrait_sub_cell{
  margin: 0.4vw;
  overflow: hidden;
}

.portrait_sub_image{
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.contact_title{
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 40px;
  text-align: left;
  margin-bottom: 4vh;
}

.contact_content{
  text-align: left;
}

.contact_info{
  margin-bottom: 0.5vh;
}

.contact_form{
  margin-top: 4vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  background-color: coral;
}

.form_column_1{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}

.form_input_1{
  margin: 0;
  height: 5vh;
}

.form_column_2{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}

.form_input_2{
  height: 15.9vh;
}

.aboutus_blank_2{
  height: 16vh;
}

.contact_button{
  height: 5vh;
  margin-top: 2vh;
  text-align: right;
}
</style>